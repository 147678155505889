
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.terms::v-deep {
    #privacy,
    #cookies {
        scroll-margin-top: 6rem;
    }
}
